<template>
  <div class="h-100vh">
    <welcoming />
  </div>
</template>

<script>
import Welcoming from "../components/Welcoming";

export default {
  name: "Home",

  components: {
    Welcoming,
  },
};
</script>
